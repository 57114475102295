import React from 'react';
import {Link} from 'gatsby';
import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
// import { withStyles } from '@material-ui/core/styles';

import Layout from '../components/layout';
import SEO from "../components/seo";

import './newsPostList.scss';

/* const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding:24,
        backgroundColor: "#E6E6E6",
        height: "100%"
    },
    control: {
        padding: theme.spacing(2),
    },
}); */


const Pagination = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 2em;
    margin-bottom: 2em;
`

const PageNumberWrapper = styled.div`
    border: 1px solid #eee;
    background: ${props => props.isCurrentPage ? '#eee' : 'white'}
`

const PageNumber = styled(Link)`
    display: block;
    padding: 8px 16px;
`

export default ({pageContext}) => (
    <Layout>
        <SEO 
            title="Merit Mile News" 
            description="Merit Mile News Description"
            />
        <div className="news-wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h2>News</h2>
                    </div>
                </div>
            </div>
            <div className="blog-wrapper">
                <div className="container grid-wrapper">
                    <Grid container spacing={24}>
                        {pageContext.posts.map(post => (
                            <Grid key={post.node.wordpress_id} className='blog_item' item xs={12} md={6}>
                                <Paper className="blog_paper">
                                    <a href={'/news/'+post.node.slug+'/'}><h3 dangerouslySetInnerHTML={{__html: post.node.title}}></h3></a>
                                    <p dangerouslySetInnerHTML={{__html: post.node.excerpt}} />
                                    <div>{post.node.date}</div>
                                    <div>{post.node.author.name}</div>
                                    <Link to={`/news/${post.node.slug}`}>
                                        <div className="btn btn-black">Read More</div>
                                    </Link>
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <Pagination>
                            {Array.from({length: pageContext.numberOfPages}).map((page, index) => (
                                <PageNumberWrapper key={index} isCurrentPage={index + 1 === pageContext.currentPage}>
                                    <PageNumber to={index === 0 ? '/news' : `/news/${index + 1}`}>
                                        {index + 1}
                                    </PageNumber>
                                </PageNumberWrapper>
                            ))}
                        </Pagination>
                    </div>
                </div>
            </div>
        </div>        
    </Layout>
)